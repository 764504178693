//Make numbers reader friendly - 1000 = 1k
export default function(number, thousandBreakpoint = 1000) {
  if (number >= 1000000)
    return (
      new Intl.NumberFormat().format(Math.round((number / 1000000) * 10) / 10) +
      "m"
    );
  if (number >= thousandBreakpoint)
    return (
      new Intl.NumberFormat().format(Math.round((number / 1000) * 10) / 10) +
      "k"
    );

  return new Intl.NumberFormat().format(Math.round(number * 10) / 10);
}
