<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Instagram report</div>
          <span class="subtitle-1 light-grey">
            Read bout
            <a href="https://www.makeinfluence.com/da/videns-center/hvad-betyder-overblikket-over-instagram-opslag-p%C3%A5-mit-dashboard" target="_blank">
              Make Influence posts
            </a>
          </span>
        </div>

        <v-spacer></v-spacer>
        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>
                {{ filtersCount }}
              </template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                  filtersTmp = cloneDeep(filters);
                  filterDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </v-card-title>

      <template v-if="isLoading">
        <template v-if="$vuetify.breakpoint.xsOnly">
          <div v-for="item in 2" :key="item">
            <v-skeleton-loader
               class="mx-auto ml-3"
               type="heading"
            ></v-skeleton-loader>
            <v-skeleton-loader
               class="mx-auto"
               type="list-item-avatar-two-line@4"
            ></v-skeleton-loader>
          </div>
        </template>
        <template v-else>
          <v-skeleton-loader
             class="mx-auto"
             type="table-thead,table-tbody"
          ></v-skeleton-loader>
        </template>
      </template>

      <template v-if="! isLoading">
        <template v-if="$vuetify.breakpoint.xsOnly">
          <v-list v-for="stat in stats" :key="stat.name" two-line>
            <v-subheader class="black--text">
              {{ stat.name }}
            </v-subheader>

            <v-list-item>
              <v-list-item-action>
                <v-icon>fal fa-images</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stat.feed_posts | local_numbers }}
                </v-list-item-title>
                <v-list-item-title>Feed posts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>fal fa-plus-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stat.story_posts | local_numbers }}
                </v-list-item-title>
                <v-list-item-title>Story posts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>fal fa-bullseye</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stat.reach | friendly_numbers }}
                </v-list-item-title>
                <v-list-item-title>Reach</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon>fal fa-eye</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stat.impressions | friendly_numbers }}
                </v-list-item-title>
                <v-list-item-title>Impressions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <!-- Not mobile version -->
        <template v-else>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Type
                </th>
                <th class="text-right">
                  Feed posts
                </th>
                <th class="text-right">
                  Story posts
                </th>
                <th class="text-right">
                  Reach
                </th>
                <th class="text-right">
                  Impressions
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                 v-for="stat in stats"
                 :key="stat.name"
              >
                <td class="text-left">{{ stat.name }}</td>
                <td class="text-right">{{ stat.feed_posts | local_numbers }}</td>
                <td class="text-right">{{ stat.story_posts | local_numbers }}</td>
                <td class="text-right">{{ stat.reach | friendly_numbers }}</td>
                <td class="text-right">{{ stat.impressions | friendly_numbers}}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </template>

    </v-card>
    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter
        </v-card-title>
        <v-card-text>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Start date"
            cancel-button
            :reset-button="false"
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="End date"
            cancel-button
            :reset-button="false"
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = cloneDeep(filtersTmp);
              filterDialog = false;
            "
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DatePicker from "@/components/common/filters/DatePicker";
import local_numbers from "@/helpers/filters/local_numbers";
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import { mapActions } from "vuex";

export default {
  components: {
    DatePicker
  },
  filters: { local_numbers, friendly_numbers },
  mixins: [search_filters_url],
  data: () => ({
    instagramCampaignStats: {},
    instagramPromotionStats: {},
    instagramStats: {},
    filterDialog: false,
    filtersTmp: {},
    filters: {},
    loadingCount: 0
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    },
    isLoading() {
      return this.loadingCount !== 2;
    },
    stats() {
      let arr = [];

      arr.push(this.instagramCampaignStats);
      // arr.push(this.instagramPromotionStats);
      arr.push(this.instagramStats);

      return arr;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.setUrlSearchFilters(this.filters);
        this.loadStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", ["getInstagramReportSummerized"]),
    loadStats() {
      this.loadingCount = 0;

      this.getInstagramCampaignStats();
      // this.getInstagramPromotionStats();
      this.getInstagramStats();
    },
    getInstagramCampaignStats() {
      let params = {
        posted_start_date: this.filters.start_date,
        posted_end_date: this.filters.end_date,
        has_campaign: true
      };

      this.getInstagramReportSummerized(params).then(response => {
        response.name = "Make Influence posts";
        this.instagramCampaignStats = response;
        this.loadingCount++;
      });
    },
    getInstagramPromotionStats() {
      let params = {
        posted_start_date: this.filters.start_date,
        posted_end_date: this.filters.end_date,
        has_promotion: true
      };

      this.getInstagramReportSummerized(params).then(response => {
        response.name = "Promotional posts";
        this.instagramPromotionStats = response;
        this.loadingCount++;
      });
    },
    getInstagramStats() {
      let params = {
        posted_start_date: this.filters.start_date,
        posted_end_date: this.filters.end_date,
      };

      this.getInstagramReportSummerized(params).then(response => {
        response.name = "All posts";
        this.instagramStats = response;
        this.loadingCount++;
      });
    },
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment().subtract(14, "days").format("YYYY-MM-DD");
      this.filters.end_date = this.$moment().format("YYYY-MM-DD");
    }
  }
};
</script>
